import React from "react"
import starIMG from "../../../components/assets/img/pages/landing/new/star.png"
import boxIMG from "../../../components/assets/img/pages/landing/new/second-section-box.png"

const SecondSection = () => {
    return (
        <section className="second">
            <div className="flexbox">
                <div>
                    <figure className="blue-box">
                        <img src={starIMG} className="star" />
                        <p className="paragraph">
                            É uma{" "}
                            <span className="yellow-font font-bold">
                                rede social
                            </span>{" "}
                            que lembra das datas comemorativas, como{" "}
                            <span className="font-bold">
                                aniversários ou um evento especial, e também
                                orienta na escolha do presente ideal,
                            </span>{" "}
                            de acordo com o desejo ou life style do presenteado.
                        </p>
                    </figure>
                    <figure className="normal-box">
                        <h1 className="blue-font">
                            Venha para
                            <br />
                            <span className="font-bold">o Gifthy</span>
                        </h1>
                        <h1 className="yellow-font">
                            Onde o futuro é<br />
                            presente!
                        </h1>
                    </figure>
                </div>
                <div className="img-box">
                    <img src={boxIMG} alt="box" />
                    <p className="white-font font-bold">
                        Também permite comprar aquele
                        <br />
                        "presente especial", através de
                        <br />
                        "vaquinha" entre os participantes.
                    </p>
                </div>
            </div>
            <div className="column">
                <h1 className="blue-font">
                    <span className="font-bold">Vamos lá!</span> Junte-se a nós
                    agora mesmo!
                </h1>
                <a
                    href="https://app.gifthy.com.br/login"
                    target="_blank"
                    rel="noreferrer"
                    className="blue-font font-bold"
                >
                    ENTRAR
                </a>
            </div>
        </section>
    )
}

export default SecondSection
